import React, {useEffect, useState} from 'react';
import api from "../../middleware/api";
import {Row, Col, Input, Modal, Spin, Select, Button, Checkbox, DatePicker, Divider, Tabs} from "antd";
import {Form, message} from "antd";
import {ExclamationCircleOutlined} from '@ant-design/icons';
import moment from "moment";
import BookingDetail from "../booking/detail";
import Table from "../../components/Table";

const {TabPane} = Tabs;


const CustomerDetail = ({id, close}) => {

    const [data, setData] = useState({});
    const [dataLoading, setDataLoading] = useState(false);
    const [saving, setSaving] = useState(false);

    const [bookingDetailId, setBookingDetailId] = useState(null);
    const [toggleBookingReloadState, setToggleBookingReloadState] = useState(null);

    const [form] = Form.useForm();

    const fetchData = async () => {
        try {
            setDataLoading(true);
            const response = await api.get(`customer/${id}`);
            if(response?.data)
            {
                const data = {...response.data,
                    createdAt: response.data.createdAt ?  moment(response.data.createdAt) : undefined,
                    updatedAt: response.data.updatedAt ? moment(response.data.updatedAt) : undefined,
                    birthdate: response.data.birthdate ? moment(response.data.birthdate) : undefined,
                };
                setData(data);
            }
        } catch(e) {
            message.error("Laden fehlgeschlagen");
        } finally {
            setDataLoading(false);
        }
    };

    const deleteData = async () => {
        Modal.confirm({
            title:
                'Möchten Sie diesen Datensatz wirklich löschen?',
            icon: <ExclamationCircleOutlined />,
            content: <span />,
            okText: 'Löschen',
            okType: 'danger',
            cancelText: 'Behalten',
            onOk: async () => {
                try {
                    setDataLoading(true);
                    await api.delete(`customer/${id}`);
                    close();
                } catch(e) {
                    message.error("Löschen fehlgeschlagen");
                } finally {
                    setDataLoading(false);
                }
            },
            onCancel() {},
        });
    };


    useEffect(() => {
        setData({});
        if (id && id !== 'new') {
            fetchData();
        }
    }, [id]);

    useEffect(() => {
        form.resetFields();
    }, [data]);


    const handleOk = () => {
        form.submit();
    };

    const handleSubmit = async (values) => {
        try {
            let resultData = {};
            setSaving(true);
            values = {
                ...values,
                birthdate: values.birthdate ? values.birthdate.local().format("YYYY-MM-DD") : undefined,
            };

            if (id && id !== 'new') {
                const res = await api.patch(`customer/${id}`, values);
                resultData = res.data;
            } else {
                const res = await api.put(`customer`, values);
                resultData = res.data;
            }

            message.success("Gespeichert");
            close(resultData);
        } catch(e) {
            message.error("Speichern fehlgeschlagen");
        } finally {
            setSaving(false);
        }
    };


    const Titles = {
        "mr": "Herr",
        "mrs": "Frau",
        "company": "Firma",
        "": "",
    };

    const Status = {
        "checked": "geprüft",
        "pending": "ausstehend",
        "confirmed": "bestätigt",
        "canceled": "storniert",
        "": "",
    };


    const columns = [
        {
            title: 'B.Nr.',
            dataIndex: 'bookingNr',
            key: 'bookingNr',
            sorter: true,
        },
        {
            title: 'Datum',
            dataIndex: 'startDate',
            key: 'startDate',
            sorter: true,
            render: (data) => `${moment(data).format('DD.MM.YYYY HH:mm')} Uhr`
        },
        {
            title: 'Name',
            dataIndex: 'firstName',
            key: 'firstName',
            sorter: true,
            render: (data, record) => `${Titles?.[record?.customer?.title || record?.guest?.title || ""]} ${record?.customer?.company || record?.guest?.company || record?.customer?.firstName || record?.guest?.firstName || ""} ${(record?.customer?.company || record?.guest?.company) ? "" : record?.customer?.lastName || record?.guest?.lastName || ""}`
        },
        {
            title: 'Produkt',
            dataIndex: 'product',
            key: 'product.name',
            sorter: true,
            render: (data) => `${data?.name}`
        },
        {
            title: 'Personen',
            dataIndex: 'persons',
            key: 'persons',
            sorter: true,
        },
        {
            title: 'Preis',
            dataIndex: 'price',
            key: 'price',
            sorter: true,
            render: (data) => data ? `${data.replaceAll(".", ",")} €` : ""
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            sorter: true,
            render: (data) => Status?.[data]
        },
        {
            title: 'Notiz',
            dataIndex: 'notes',
            key: 'notes',
            sorter: true,
            render: (data, record) => data && data.length > 10 ? `${data.substring(0, 10)}...` : data
        },
        {
            title: 'Erstellt',
            dataIndex: 'createdAt',
            key: 'createdAt',
            sorter: true,
            render: (data) => `${moment(data).format('DD.MM.YYYY HH:mm')} Uhr`
        },

    ];

    const toggleBookingReload = () => {
        setToggleBookingReloadState(Math.random(5));
    };

    const closeBookingDetail = () => {
        setBookingDetailId(null);
        toggleBookingReload();
    };


    return (
        <>
            <BookingDetail
                id={bookingDetailId}
                close={closeBookingDetail}
            />

            <Modal
                maskClosable={false}
                destroyOnClose={true}
                visible={!!id}
                title={id !== 'new' ? "Kunde bearbeiten" : "Kunde anlegen"}
                onCancel={close}
                onOk={handleOk}
                width={"1440px"}
                footer={[
                    <>
                        {id !== 'new' ?
                            <Button key="delete" type="danger" onClick={deleteData}>
                                Löschen
                            </Button>
                        : null}
                    </>,
                    <Button key="cancel" type="default" onClick={close}>
                        Abbrechen
                    </Button>,
                    <Button key="ok" type="primary" onClick={handleOk}>
                        Speichern
                    </Button>,

                ]}
            >
                <Spin spinning={dataLoading || saving}>
                    <Form
                        form={form}
                        onFinish={handleSubmit}
                        initialValues={data}
                        layout={"vertical"}
                    >

                        <Tabs defaultActiveKey="1" >
                            <TabPane tab="Kundendaten" key="1">
                                <Row
                                    gutter={16}
                                >
                                    <Col span={24}>
                                        <Divider orientation="left">Kundendaten</Divider>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            label={"Anrede"}
                                            name={"title"}
                                            required={true}
                                            rules={[{required: true}]}
                                        >
                                            <Select>
                                                <Select.Option value={"mr"}>Herr</Select.Option>
                                                <Select.Option value={"mrs"}>Frau</Select.Option>
                                                <Select.Option value={"company"}>Firma</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>

                                    <Form.Item shouldUpdate noStyle>
                                        {() => form.getFieldsValue(['title'])?.title === "company" ?
                                        <Col span={12}>
                                            <Form.Item
                                                label={"Firma"}
                                                name={"company"}
                                                required={true}
                                                rules={[{required: true}]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        : null }
                                    </Form.Item>

                                    <Col span={12}>
                                        <Form.Item
                                            label={"Vorname"}
                                            name={"firstName"}
                                            required={true}
                                            rules={[{required: true}]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            label={"Nachname"}
                                            name={"lastName"}
                                            required={true}
                                            rules={[{required: true}]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>

                                    <Col span={12}>
                                        <Form.Item
                                            label={"Straße, Hausnummer"}
                                            name={"address"}
                                            required={true}
                                            rules={[{required: true}]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            label={"PLZ"}
                                            name={"zipCode"}
                                            required={true}
                                            rules={[{required: true, len: 5}]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            label={"Ort"}
                                            name={"city"}
                                            required={true}
                                            rules={[{required: true}]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            label={"Geburtsdatum"}
                                            name={"birthdate"}
                                        >
                                            <DatePicker style={{width: '100%'}} format={"DD.MM.YYYY"} />
                                        </Form.Item>
                                    </Col>

                                    <Col span={24}>
                                        <Divider orientation="left">Kontaktdaten</Divider>
                                    </Col>

                                    <Col span={12}>
                                        <Form.Item
                                            label={"E-Mail Adresse"}
                                            name={"mail"}
                                            rules={[ {type: 'email', required: true}]}
                                        >
                                            <Input type={"email"} />
                                        </Form.Item>
                                    </Col>

                                    <Col span={12}>
                                        <Form.Item
                                            label={"Telefon"}
                                            name={"phone"}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>

                                    <Col span={12}>
                                        <Form.Item
                                            label={"Newsletter"}
                                            name={"newsletter"}
                                            valuePropName={"checked"}
                                        >
                                            <Checkbox />
                                        </Form.Item>
                                    </Col>

                                    <Col span={12}>
                                        <Form.Item
                                            label={"Aktiv"}
                                            name={"isActive"}
                                            valuePropName={"checked"}
                                        >
                                            <Checkbox />
                                        </Form.Item>
                                    </Col>

                                    <Col span={12}>
                                        <Form.Item
                                            label={"E-Mail Adresse bestätigt"}
                                            name={"emailConfirmed"}
                                            valuePropName={"checked"}
                                        >
                                            <Checkbox />
                                        </Form.Item>
                                    </Col>

                                    <Col span={24}>
                                        <Divider orientation="left">Informationen</Divider>
                                    </Col>

                                    <Col span={12}>
                                        <Form.Item
                                            label={"Erstellt"}
                                            name={"createdAt"}
                                        >
                                            <DatePicker showTime style={{width: '100%'}} disabled format={"DD.MM.YYYY HH:mm"} />
                                        </Form.Item>
                                    </Col>

                                    <Col span={12}>
                                        <Form.Item
                                            label={"Zuletzt bearbeitet"}
                                            name={"updatedAt"}
                                        >
                                            <DatePicker showTime style={{width: '100%'}} disabled format={"DD.MM.YYYY HH:mm"} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </TabPane>


                            <TabPane tab="Reservierungen" key="2">
                                <Table
                                    endpoint={'booking'}
                                    filter={{customerId: id}}
                                    columns={columns}
                                    sortField={'createdAt'}
                                    sortOrder={'descend'}
                                    onClick={(record) => setBookingDetailId(record.id)}
                                    toggleReload={toggleBookingReloadState}
                                />
                            </TabPane>

                            <TabPane tab="Gutscheine" key="3">
                            </TabPane>





                        </Tabs>

                    </Form>
                </Spin>
            </Modal>
        </>
    );
}

export default CustomerDetail;
